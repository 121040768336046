<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ purchasePayment.vendorName }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Pay Date <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatDate(purchasePayment.paymentDate) }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Voucher Number <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ purchasePayment.voucherNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Method <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ purchasePayment.methodPayment }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Bank Charge <span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatPrice(purchasePayment.bankCharge) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader> Round<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ purchasePayment.round }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader> Round Amount<span class="red--text">*</span></v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ formatPrice(purchasePayment.roundAmount) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Remark</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ purchasePayment.remark }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat class="mb-2">
            <v-row>
              <v-col cols="12" align-self="start" class="text-right pb-0">
                <p class="headline font-italic">Invoice <span class="red--text">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="purchasePayment.invoiceDtoList"
                  disable-pagination
                  hide-default-footer
                  dense
                >
                  <template v-slot:item.no="{ item }">
                    {{ purchasePayment.invoiceDtoList.map(x => x).indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.totalAmountAfterTax="{ item }">
                    {{ formatPrice(item.totalAmountAfterTax) }}
                  </template>
                  <template v-slot:item.rate="{ item }">
                    {{ formatPrice(item.rate) }}
                  </template>
                  <template v-slot:item.totalInvoiceIDR="{ item }">
                    {{ formatPrice(item.totalInvoiceIDR) }}
                  </template>
                  <template v-slot:item.invoicePaid="{ item }">
                    {{ formatPrice(item.invoicePaid) }}
                  </template>
                  <template v-slot:item.totalInvoicePaidIDR="{ item }">
                    {{ formatPrice(item.totalInvoicePaidIDR) }}
                  </template>
                  <template v-slot:item.fullPayment="{ item }">
                    {{ item.fullPayment ? "YES" : "NO" }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card flat class="mb-2">
            <v-row>
              <v-col cols="12" align-self="start" class="text-right pb-0">
                <p class="headline font-italic">Bank / Cash <span class="red--text">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headerBank"
                  :items="purchasePayment.accounts"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.no="{ item }">
                    {{ purchasePayment.accounts.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ formatPrice(item.amount) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="purchasePayment.methodPayment === 'Check'">
          <v-card flat class="mb-2">
            <v-row>
              <v-col cols="12" align-self="start" class="text-right pb-0">
                <p class="headline font-italic">Check<span class="red--text">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headerCheck"
                  :items="purchasePayment.checks"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.no="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ formatPrice(item.amount) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="purchasePayment.methodPayment === 'Giro'">
          <v-card flat class="mb-2">
            <v-row>
              <v-col cols="12" align-self="start" class="text-right pb-0">
                <p class="headline font-italic">Giro<span class="red--text">*</span></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headerGiro"
                  :items="purchasePayment.giros"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.no="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template v-slot:item.amount="{ item }">
                    {{ formatPrice(item.amount) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title>Total Paid</v-card-title>
            <v-card-text class="text-right">
              <span>{{ formatPrice(total) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card outlined>
            <v-card-title>Total Paid (IDR)</v-card-title>
            <v-card-text class="text-right">
              <span>Rp. {{ formatPrice(totalIDR) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  nama: "detail-purchase-payment",
  props: {
    purchasePayment: Object,
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "invoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "PPH",
        value: "pphName",
        sortable: false,
        divider: true,
      },
    ],
    headerBank: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "AccountName",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerCheck: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Check Number",
        value: "checkNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Amountr",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerGiro: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Giro Number",
        value: "giroNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    total() {
      let total = 0;
      if (this.purchasePayment && this.purchasePayment.invoiceDtoList) {
        this.purchasePayment.invoiceDtoList.forEach(x => {
          total = total + x.invoicePaid;
        });
      }
      return total;
    },
    totalIDR() {
      let total = 0;
      if (this.purchasePayment && this.purchasePayment.invoiceDtoList) {
        this.purchasePayment.invoiceDtoList.forEach(x => {
          total += x.totalInvoicePaidIDR;
        });
      }
      return total;
    },
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>

<style></style>
