<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="purchasePayment"
          :vendors="vendors"
          :listBanks="listBanks"
          :listInvoice="listInvoice"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :purchasePayment="purchasePayment" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="updateItem">
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="printer">
              <v-icon>
                mdi-printer
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="success" @click="editItem">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-btn color="error" @click="dialogDelete">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="purchasePayment.voucherNumber"
      title="Purchase Payment"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailPurchasePayment";
import FormEdit from "./FormPurchasePayment";
import { mapState } from "vuex";
import DialogDelete from "@/components/DialogDelete";

export default {
  name: "purchase-payment-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    listBanks: [],
    listInvoice: [],
    purchasePayment: {},
    dialog: false,
  }),
  computed: {
    ...mapState("external", ["vendors"]),
  },
  methods: {
    async getPurchasePayment() {
      await this.$store
        .dispatch("purchasePayment/getById", this.id)
        .then(response => (this.purchasePayment = response.data));
    },
    async getListInvoice() {
      await this.$store
        .dispatch("purchasePayment/getListInvoiceById", this.id)
        .then(response => (this.listInvoice = response.data));
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    dialogDelete() {
      this.dialog = true;
    },
    async deleteItem(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/delete", id)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.edit = true;
    },
    getBanks() {
      this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBanks = response.data;
      });
    },
    printer() {
      this.$router.push({ name: "Payment Voucher Purchase", params: { id: this.id } });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getBanks(),
        this.getPurchasePayment(),
        this.$store.dispatch("external/getVendors"),
        this.getListInvoice(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
